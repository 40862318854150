<template>
  <BaseDetailSimple
    :input-attributes="inputAttributes"
    :leave-guard="leaveGuard"
    :toggle="toggle"
    :query-values="queryValues"
    entity-type="signum1"
    locale-section="pages.signa1"
    field-section="signa1"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "Signum1DetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        _name: signum1
        signum1
        source
        shortSource
        altschema
        deprecated
      `,
      toggle: {
        altschema: {
          altschema: true,
          notAltschema: false
        },
        deprecated: {
          deprecated: true,
          notDeprecated: false
        }
      },
      inputAttributes: {
        signum1: { required: true },
        source: { required: true },
        shortSource: { required: true }
      }
    };
  }
};
</script>
